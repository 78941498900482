import inboxAddressAndBank from 'assets/images/illu-adress-and-bank.svg'
import inboxIllustration from 'assets/images/illu-inbox.svg'
import phoneIllustration from 'assets/images/illu-phone.svg'

import { Trans } from 'react-i18next'
import routesDictionary from 'routes'
import { useRouteHelper } from 'shared/hooks/useRouteHelper'

const Dashboard = () => {
	const { navigateTo, getMainPath, isRouteAvailable } = useRouteHelper()

	return (
		<div className="dashboard">
			{/* TODO: Items need own component */}
			{isRouteAvailable(routesDictionary.globalInbox) && (
				<div
					onClick={() => {
						navigateTo(getMainPath(routesDictionary.globalInbox))
					}}
					className="dashboard__item dashboard__item--2 flex flex--direction-column flex--justify-content-end "
				>
					<div className="background background--illu background--50">
						<img src={inboxIllustration} alt="" />
					</div>
					<h1 className="no-margin--bottom">
						<Trans i18nKey={'view.dashboard.inboxItem.headline'} />
					</h1>
					<div className="flex flex-s-align-items-center">
						<div className="text-transform-uppercase text-color-gold margin--right">
							<Trans i18nKey={'view.dashboard.inboxItem.subheadline'} />
						</div>
						<hr className="hr hr--gold flex--grow" />
					</div>
				</div>
			)}
			{isRouteAvailable(routesDictionary.archive) && (
				<div
					className="dashboard__item flex flex--direction-column flex--justify-content-end"
					onClick={() => {
						navigateTo(getMainPath(routesDictionary.archive))
					}}
				>
					<h1 className="no-margin--bottom">
						<Trans i18nKey={'view.dashboard.archiveItem.headline'} />
					</h1>
					<div className="flex flex-s-align-items-center ">
						<div className="text-transform-uppercase text-color-gold margin--right">
							<Trans i18nKey={'view.dashboard.archiveItem.subheadline'} />
						</div>
						<hr className="hr hr--gold flex--grow" />
					</div>
				</div>
			)}

			{isRouteAvailable(routesDictionary.pensionerSearch) && (
				<div
					className="dashboard__item dashboard__item--2 flex flex--direction-column flex--justify-content-end 
				"
					onClick={() => {
						navigateTo(getMainPath(routesDictionary.pensionerSearch))
					}}
				>
					<h1 className="no-margin--bottom">
						<Trans i18nKey={'view.dashboard.searchItem.headline'} />
					</h1>
					<div className="flex flex-s-align-items-center ">
						<div className="text-transform-uppercase text-color-gold margin--right">
							<Trans i18nKey={'view.dashboard.searchItem.subheadline'} />
						</div>
						<hr className="hr hr--gold flex--grow" />
					</div>
				</div>
			)}

			{isRouteAvailable(routesDictionary.admin) && (
				<div
					className="dashboard__item flex flex--direction-column flex--justify-content-end"
					onClick={() => {
						navigateTo(getMainPath(routesDictionary.admin))
					}}
				>
					<h1 className="no-margin--bottom">
						<Trans i18nKey={'view.dashboard.adminItem.headline'} />
					</h1>
					<div className="flex flex-s-align-items-center">
						<div className="text-transform-uppercase text-color-gold margin--right">
							<Trans i18nKey={'view.dashboard.adminItem.subheadline'} />
						</div>
						<hr className="hr hr--gold flex--grow" />
					</div>
				</div>
			)}

			{isRouteAvailable(routesDictionary.callerActions) && (
				<div
					onClick={() => {
						navigateTo(getMainPath(routesDictionary.callerActions))
					}}
					className="dashboard__item dashboard__item--full flex flex--direction-column flex--justify-content-end "
				>
					<div className="background background--illu background--50">
						<img src={inboxAddressAndBank} alt="" />
					</div>
					<h1 className="no-margin--bottom">
						<Trans i18nKey={'view.dashboard.callerActions.headline'} />
					</h1>
					<div className="flex flex-s-align-items-center ">
						<div className="text-transform-uppercase text-color-gold margin--right">
							<Trans i18nKey={'view.dashboard.callerActions.subheadline'} />
						</div>
						<hr className="hr hr--gold flex--grow" />
					</div>
				</div>
			)}

			{isRouteAvailable(routesDictionary.callerHistory) && (
				<div
					onClick={() => {
						navigateTo(getMainPath(routesDictionary.callerHistory))
					}}
					className="dashboard__item dashboard__item--full flex flex--direction-column flex--justify-content-end "
				>
					<div className="background background--illu background--50 padding">
						<img src={phoneIllustration} alt="" />
					</div>
					<h1 className="no-margin--bottom">
						<Trans i18nKey={'view.dashboard.callerHistory.headline'} />
					</h1>
					<div className="flex flex-s-align-items-center ">
						<div className="text-transform-uppercase text-color-gold margin--right">
							<Trans i18nKey={'view.dashboard.callerHistory.subheadline'} />
						</div>
						<hr className="hr hr--gold flex--grow" />
					</div>
				</div>
			)}
		</div>
	)
}

export default Dashboard
