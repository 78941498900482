import GridList from 'components/GridList'
import GridListCell from 'components/GridListCell'
import GridListRow from 'components/GridListRow'
import { IconType } from 'components/Icons'
import { ViewHeader } from 'components/ViewHeader'
import { activatedFeatures } from 'helper/activatedFeatures'
import useApi, { QueryKey } from 'hooks/useApi'
import { useUserRecord } from 'hooks/useUserRecord'
import { useQueryParams } from 'raviger'
import { FunctionComponent, useEffect, useMemo, useRef, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import Button, { ButtonType } from 'shared/components/Button'
import Icon from 'shared/components/Icon'
import Modal, { ModalRefActions } from 'shared/components/Modal'
import dateFormat from 'shared/helper/dateFormat'
import hourFormat from 'shared/helper/hourFormat'
import { useDetectClickOutside } from 'shared/hooks/useDetectClickOutside'
import Austrittsdaten from '../Austrittsbearbeitung/PensionerAustrittsbearbeitungen/Austrittsdaten'
import Finalisierung from '../Austrittsbearbeitung/PensionerAustrittsbearbeitungen/Finalisierung'
import Stammdaten from '../Austrittsbearbeitung/PensionerAustrittsbearbeitungen/Stammdaten'
import Assets from './PensionerAustrittsbearbeitungen/Assets'
import FinalLeistungsfall, { LeistungsfallInfo } from './PensionerAustrittsbearbeitungen/FinalLeistungsfall'
import Dokumente from './PensionerAustrittsbearbeitungen/Dokumente'
import FinalUVFall, { UVFallInfo } from './PensionerAustrittsbearbeitungen/FinalUVFall'
import PayoutModel from './PensionerAustrittsbearbeitungen/PayoutModel'

type HorizontalTab = {
	icon: IconType
	id: string
	headline: string | JSX.Element
	content: JSX.Element | null
	open?: boolean
	disabled?: boolean
}

const PensionerAustrittsbearbeitungen: React.FC = () => {
	const api = useApi()
	const { t } = useTranslation()
	const [historyOpen, setHistoryOpen] = useState(false)
	const { userId } = useUserRecord()
	const [queryParams] = useQueryParams()

	useEffect(() => {
		const view = queryParams.view

		if (view) {
			toggleTab(view)
		}
	}, [queryParams.view])

	const { data: versorgungsguthaben } = useQuery(['assets', { userId }], api.getAustrittVersorgungsguthaben, {
		enabled: !!userId,
	})
	const { data: stammdaten } = useQuery(
		[QueryKey.austrittsbearbeitungen, { userId }],
		api.getAustrittsbearbeitungenStammdatenByIdentNumber,
		{
			enabled: !!userId,
		}
	)
	const { fallart, versorgungsordnung } = stammdaten || {}
	const { hasAbfindung, gesamtbeitrag, ohneArbeitgeberanteile, bausteinkonto, istUnterAuszahlungsartWahlGrenze } =
		versorgungsguthaben || {}

	const { data: filters } = useQuery(
		[QueryKey.austrittsbearbeitungStatus, { filterType: 'status' }],
		api.getAustrittsbearbeitungFilterTypes
	)

	const { data: bearbeitende } = useQuery(
		[QueryKey.austrittsbearbeitungen, {}],
		api.getAustrittsbearbeitungenBearbeitende
	)

	const { data: austrittAuszahlungsoption } = useQuery(
		['payoutModel', { userId }],
		api.getAustrittsAuszahlungsmodell,
		{
			enabled: !!userId,
		}
	)

	const { data: historie, refetch: refetchHistorie } = useQuery(
		[QueryKey.austrittsbearbeitungenHistorie, { userId }],
		api.getAustrittsbearbeitungenHistorieByIdentNumber,
		{ enabled: !!userId }
	)

	const { data: confirmedAuszahlung, isLoading: confirmedAuszahlungIsLoading } = useQuery(
		[QueryKey.confirmedAuszahlungGet, { userId }],
		api.getConfirmedAuszahlung,
		{
			enabled: !!userId,
		}
	)

	const { data: auszahlungsoption, isLoading: auszahlungsoptionLoading } = useQuery(
		['payoutModel', { userId }],
		api.getAustrittsAuszahlungsmodell,
		{
			enabled: !!userId,
		}
	)

	const statusOptions = useMemo(
		() =>
			filters?.map((v) => {
				return {
					value: v,
					label: t(`component.austrittsbearbeitungTable.status.${v}`),
				}
			}),
		[filters, t]
	)

	const { firstname, lastname } = useUserRecord()
	const isUVFall = fallart === 'UV_FALL'
	const isLeistungsfall = fallart === 'LEISTUNGSFALL'
	const hasNoFallart = fallart === undefined
	const hasNoVersorgungsguthaben = gesamtbeitrag === 0
	const isVO20 = versorgungsordnung === 'VO20'
	const versorgungsguthabenOver15k = !istUnterAuszahlungsartWahlGrenze
	const abfindungFall = hasAbfindung === true
	const hasBausteine = bausteinkonto && bausteinkonto.gesamtbeitrag > 0
	const uvFallInfo: UVFallInfo | undefined = isUVFall
		? {
			verfallbar: !!ohneArbeitgeberanteile,
			bausteinkonto: !!hasBausteine,
			abfindung: abfindungFall,
		}
		: undefined
	const mixedWithRaten =
		austrittAuszahlungsoption &&
		austrittAuszahlungsoption.auszahlungsModell?.type === 'mixed' &&
		(austrittAuszahlungsoption.auszahlungsModell.absoluteAmounts?.instalments10Years ||
			austrittAuszahlungsoption.auszahlungsModell.absoluteAmounts?.instalments20Years ||
			austrittAuszahlungsoption.auszahlungsModell.distribution?.instalments10Years ||
			austrittAuszahlungsoption.auszahlungsModell.distribution?.instalments20Years)
	const hasRaten =
		austrittAuszahlungsoption &&
		(austrittAuszahlungsoption.auszahlungsModell?.type === 'instalments10Years' ||
			austrittAuszahlungsoption.auszahlungsModell?.type === 'instalments20Years')
	const hasAuszahlungsplan = !!(hasRaten || mixedWithRaten)
	const leistungsfallInfo: LeistungsfallInfo | undefined = isLeistungsfall
		? {
			bausteinkonto: !!hasBausteine,
			underThreshold: !versorgungsguthabenOver15k,
			hasAuszahlungsplan,
		}
		: undefined
	const rueckkonvertierbareRente = confirmedAuszahlung?.rueckkonvertierung
	const maxBetragRueckkonvertierbareRente = rueckkonvertierbareRente?.maxRueckkonvertierbarerBetrag
	const hasRueckkonvertierbarerBetrag = !!maxBetragRueckkonvertierbareRente

	const formattedHistory = useMemo(() => {
		return (
			<GridList columnCount={4} className="historie">
				<GridListRow>
					<GridListCell className={'grid-list__cell--header'}>
						{t(`component.austrittsbearbeitungTable.bearbeitungsHistorie.date`)}
					</GridListCell>
					<GridListCell className={'grid-list__cell--header'}>
						{t(`component.austrittsbearbeitungTable.bearbeitungsHistorie.time`)}
					</GridListCell>{' '}
					<GridListCell className={'grid-list__cell--header'}>
						{t(`component.austrittsbearbeitungTable.bearbeitungsHistorie.editor`)}
					</GridListCell>{' '}
					<GridListCell className={'grid-list__cell--header'}>
						{t(`component.austrittsbearbeitungTable.bearbeitungsHistorie.status`)}
					</GridListCell>
				</GridListRow>

				{historie?.map((v, i) => {
					return (
						<GridListRow key={'history-' + i}>
							<GridListCell>{dateFormat(new Date(v.createdAt))}</GridListCell>
							<GridListCell>{hourFormat(new Date(v.createdAt))}</GridListCell>{' '}
							<GridListCell>
								<div
									className="tag"
									// TODO DA adding color for bearbeitende if the info avail.
									style={
										{
											background: v.bearbeitendePerson ? '' : 'grey',
											fontSize: 'medium',
										} as React.CSSProperties
									}
								>
									{v.bearbeitendePerson?.tag ?? '-'}
								</div>
							</GridListCell>{' '}
							<GridListCell>
								<Button
									type={ButtonType.small}
									label={t(`component.austrittsbearbeitungTable.status.${v.status}`)}
									className={`button--status--${v.status}`}
								></Button>
							</GridListCell>
						</GridListRow>
					)
				})}
			</GridList>
		)
	}, [historie, t])

	function createEndpoint(): string {
		return abfindungFall ? 'AbfindungsSchreiben' : 'UVSchreiben'
	}

	const getAustrittsTabs = (
		isUVFall: boolean,
		abfindungFall: boolean,
		hasNoVersorgungsguthaben: boolean,
		isLeistungsfall: boolean
	): HorizontalTab[] => {
		const commonTabs = [
			{
				headline: <Trans i18nKey={'view.pensionerProfile.tabs.person.headline'}></Trans>,
				icon: IconType.profile,
				content: <Stammdaten></Stammdaten>,
				id: 'person',
			},
			{
				headline: <Trans i18nKey={'view.pensionerProfile.tabs.austrittsDaten.headline'}></Trans>,
				icon: IconType.documents,
				content: <Austrittsdaten></Austrittsdaten>,
				id: 'austrittsdaten',
			},
			{
				headline: <Trans i18nKey={'view.pensionerProfile.tabs.assets.headline'}></Trans>,
				icon: IconType.money,
				content: <Assets isLeistungsfall={isLeistungsfall}></Assets>,
				id: 'berechnungs-anspruch',
			},
		]

		if (hasNoVersorgungsguthaben) {
			return [
				...commonTabs,
				{
					headline: <Trans i18nKey={'view.pensionerProfile.tabs.finalisierung.headline'}></Trans>,
					icon: IconType.approved,
					content: <Finalisierung refetchHistorie={refetchHistorie}></Finalisierung>,
					id: 'finalisierung',
				},
			]
		}

		if (isUVFall && !abfindungFall && uvFallInfo) {
			return [
				...commonTabs,
				{
					headline: <Trans i18nKey={`view.pensionerProfile.tabs.uvFall.UVSchreiben`}></Trans>,
					icon: IconType.approved,
					content: (
						<FinalUVFall
							endpoint={createEndpoint()}
							headline={'component.uvFall.UVSchreiben'}
							refetchHistorie={refetchHistorie}
							uvFallInfo={uvFallInfo}
							isAbfindung={abfindungFall}
						></FinalUVFall>
					),
					id: 'uv-schreiben',
				},
			]
		}

		if (isUVFall && abfindungFall && uvFallInfo) {
			return [
				...commonTabs,
				{
					headline: <Trans i18nKey={`view.pensionerProfile.tabs.uvFall.AbfindungsSchreiben`}></Trans>,
					icon: IconType.approved,
					content: (
						<FinalUVFall
							endpoint={createEndpoint()}
							headline={'component.uvFall.AbfindungsSchreiben'}
							refetchHistorie={refetchHistorie}
							uvFallInfo={uvFallInfo}
							isAbfindung={abfindungFall}
						></FinalUVFall>
					),
					id: 'abfindungschreiben',
				},
				{
					headline: <Trans i18nKey={'view.pensionerProfile.tabs.dokumente'}></Trans>,
					icon: IconType.documents,
					content: <Dokumente fallart={fallart} isAbfindungsFall={abfindungFall} refetchHistorie={refetchHistorie} />,
					id: 'dokumente',
				},
			]
		}

		if ((isLeistungsfall && versorgungsguthabenOver15k) || hasNoFallart) {
			if (confirmedAuszahlungIsLoading && auszahlungsoptionLoading) {
				return [...commonTabs]
			}
			return [
				...commonTabs,
				{
					headline: <Trans i18nKey={'view.pensionerProfile.tabs.payoutModel.headline'}></Trans>,
					icon: IconType.payout,
					content: (
						<PayoutModel
							simulate={false}
							auszahlungsoption={auszahlungsoption}
							showToggle={hasRueckkonvertierbarerBetrag && isVO20}
							austrittsDatum={stammdaten?.austrittsdatum}
							rueckkonvertierbareRenteMax={rueckkonvertierbareRente?.maxRueckkonvertierbarerBetrag ?? 0}
							gewaehlterBetrag={
								rueckkonvertierbareRente?.gewaehlterBetrag === null
									? 0
									: rueckkonvertierbareRente?.gewaehlterBetrag
							}
						></PayoutModel>
					),
					id: 'auszahlungsmodell',
				},
				{
					headline: <Trans i18nKey={'view.pensionerProfile.tabs.payoutModelSimulieren.headline'}></Trans>,
					icon: IconType.simulate,
					content: (
						<PayoutModel
							simulate={true}
							auszahlungsoption={auszahlungsoption}
							showToggle={hasRueckkonvertierbarerBetrag && isVO20}
							austrittsDatum={stammdaten?.austrittsdatum}
							gewaehlterBetrag={
								rueckkonvertierbareRente?.gewaehlterBetrag === null
									? 0
									: rueckkonvertierbareRente?.gewaehlterBetrag
							}
							rueckkonvertierbareRenteMax={rueckkonvertierbareRente?.maxRueckkonvertierbarerBetrag ?? 0}
						></PayoutModel>
					),
					id: 'auszahlungsmodell-simulieren',
				},
				{
					headline: <Trans i18nKey={'view.pensionerProfile.tabs.dokumente'}></Trans>,
					icon: IconType.documents,
					content: <Dokumente fallart={fallart} refetchHistorie={refetchHistorie} />,
					id: 'dokumente',
				},
				{
					headline: <Trans i18nKey={'view.pensionerProfile.tabs.zusageschreiben.headline'}></Trans>,
					icon: IconType.approved,
					content: (
						<FinalLeistungsfall refetchHistorie={refetchHistorie} leistungsfallInfo={leistungsfallInfo} />
					),
					id: 'zusageschreiben',
				},
			]
		}

		if (isLeistungsfall && !versorgungsguthabenOver15k) {
			return [
				...commonTabs,
				{
					headline: <Trans i18nKey={'view.pensionerProfile.tabs.zusageschreiben.headline'}></Trans>,
					icon: IconType.approved,
					content: (
						<FinalLeistungsfall refetchHistorie={refetchHistorie} leistungsfallInfo={leistungsfallInfo} />
					),
					id: 'zusageschreiben',
				},
				{
					headline: <Trans i18nKey={'view.pensionerProfile.tabs.dokumente'}></Trans>,
					icon: IconType.documents,
					content: <Dokumente fallart={fallart} refetchHistorie={refetchHistorie} />,
					id: 'dokumente',
				},
			]
		}

		return commonTabs
	}

	const austrittsTabs = useMemo(() => {
		return getAustrittsTabs(isUVFall, abfindungFall, hasNoVersorgungsguthaben, isLeistungsfall)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		isUVFall,
		abfindungFall,
		hasNoVersorgungsguthaben,
		isLeistungsfall,
		versorgungsguthabenOver15k,
		uvFallInfo,
		leistungsfallInfo,
	])

	const [openTabs, setOpenTabs] = useState([austrittsTabs[0].id])

	const toggleTab = (tab: string) =>
		setOpenTabs((state) => {
			const newState = [...state]

			const index = newState.indexOf(tab)

			if (index === -1) {
				if (newState.length === 3) {
					newState.splice(0, 1)
				}

				newState.push(tab)
			} else {
				newState.splice(index, 1)

				return newState
			}

			return newState
		})

	const handleBearbeitendeChanged = async (v: string) => {
		if (!v) {
			return
		}
		const payload = bearbeitende?.find((entry) => entry.key === v)
		if (!payload || !userId) {
			return
		}
		await api.editAustrittstask(userId, { bearbeitendePerson: payload })
		refetchHistorie()
	}
	const handleStatusChanged = async (v: string) => {
		if (!userId) {
			return
		}
		await api.editAustrittstask(userId, { status: v as any })
		refetchHistorie()
	}

	const bearbeitendeSelectOptions = bearbeitende?.map((v) => {
		return {
			value: v.key,
			label: (
				<>
					<div
						className="button-select__tag"
					// // TODO DA adding color for bearbeitende if the info avail.
					// style={
					// 	{
					// 		background: props.error ? 'grey' : '',
					// 		fontSize: 'medium',
					// 	} as React.CSSProperties
					// }
					>
						{v.tag}
					</div>
					<span>
						{v.salutation?.firstName} {v.salutation?.lastName}
					</span>
				</>
			),
		}
	})

	const handleHistoryClick = () => {
		setHistoryOpen(true)
	}

	return (
		<div className="pensioner-profile">
			{bearbeitende && (
				<>
					<ViewHeader
						headline={
							<Trans
								i18nKey="view.austrittsbearbeitung.akkordeon.headline"
								values={{ firstname, lastname }}
							/>
						}
						subheadline={<Trans i18nKey="view.austrittsbearbeitung.akkordeon.subheadline" />}
					></ViewHeader>
					<div className="pensioner-profile__bar flex">
						<div className="austrittsstatus-bar">
							<div className="austrittsstatus-bar__label">
								{t(`component.austrittsbearbeitungTable.setStatus`) + ' :'}
							</div>
							{statusOptions && historie && historie[historie.length - 1].status && (
								<ButtonSelect
									options={statusOptions.map((v) => {
										return {
											...v,
											label: (
												<>
													<span className="austrittsstatus-bar__indicator"></span>
													{v.label}
												</>
											),
										}
									})}
									className={`austrittsstatus-bar__input--${historie[historie.length - 1].status}`}
									value={historie[historie.length - 1].status}
									onSelect={handleStatusChanged}
								></ButtonSelect>
							)}
							{statusOptions && !historie && (
								<ButtonSelect
									options={statusOptions}
									className={`austrittsstatus-bar__input--error`}
									value={statusOptions[0].value}
									onSelect={handleStatusChanged}
								></ButtonSelect>
							)}
						</div>
						<div className="bearbeitende-select">
							{bearbeitendeSelectOptions && bearbeitende && historie && historie.length > 0 && (
								<ButtonSelect
									options={bearbeitendeSelectOptions}
									onSelect={handleBearbeitendeChanged}
									value={historie[historie.length - 1].bearbeitendePerson?.key}
									append={
										<Button
											type={ButtonType.primary}
											onClick={handleHistoryClick}
											className="margin button-select__history-button"
										>
											{t(`component.austrittsbearbeitungTable.bearbeitungsHistorie.headline`)}
										</Button>
									}
								></ButtonSelect>
							)}
							{bearbeitende && !historie && (
								<ButtonSelect
									error={true}
									options={bearbeitende as any}
									onSelect={handleBearbeitendeChanged}
									value={bearbeitende[0].key}
									append={
										<Button
											type={ButtonType.primary}
											onClick={handleHistoryClick}
											className="margin bearbeitende-person-select__history-button"
										>
											{t(`component.austrittsbearbeitungTable.bearbeitungsHistorie.headline`)}
										</Button>
									}
								></ButtonSelect>
							)}
						</div>
					</div>

					<div className="horzontal-tabs">
						{austrittsTabs.map((v, i) => {
							if (v.disabled) {
								return <div key={`no-tab-${i}`}></div>
							}
							return (
								<section
									key={`horizontal-tab-${i}`}
									className={`horizontal-tab horizontal-tab--${openTabs.includes(v.id) ? 'open' : 'closed'
										} horizontal-tab--${v.id}`}
								>
									<header className="horizontal-tab__header" onClick={() => toggleTab(v.id)}>
										<Icon type={v.icon}></Icon>{' '}
										<h2 className="horizontal-tab__headline">{v.headline}</h2>
									</header>
									<div className="horizontal-tab__content">
										<Icon
											className="horizontal-tab__close"
											type={IconType.close}
											onClick={() => toggleTab(v.id)}
										/>
										{v.content}
									</div>
								</section>
							)
						})}
					</div>
					<BearbeitendeHistoryModal
						open={historyOpen}
						onModalClose={() => {
							setHistoryOpen(false)
						}}
						content={formattedHistory || <></>}
					></BearbeitendeHistoryModal>
				</>
			)}
		</div>
	)
}

export interface BearbeitenHistoryModalProps {
	open: boolean
	onModalClose: () => void
	content: any
}

const BearbeitendeHistoryModal: FunctionComponent<BearbeitenHistoryModalProps> = (props) => {
	const { open } = props
	const modal = useRef<ModalRefActions>()
	const { t } = useTranslation()

	useEffect(() => {
		if (open) {
			modal.current?.openModal()
		}
	}, [open])

	return (
		<Modal
			ref={modal}
			forceAction={false}
			onModalClose={props.onModalClose}
			className="bearbeitende-history-modal modal--width-big"
			hideConfirm={true}
			header={<h1>{t(`component.austrittsbearbeitungTable.bearbeitungsHistorie.headline`)}</h1>}
		>
			<ViewHeader
				headline={''}
				subheadline={t(`component.austrittsbearbeitungTable.bearbeitungsHistorie.subheadline`)}
			></ViewHeader>
			{props.content}
		</Modal>
	)
}

export default PensionerAustrittsbearbeitungen

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ButtonSelect: FunctionComponent<{
	options: { label: string | JSX.Element; value: string }[]
	onSelect: (value: string) => void
	value: string | undefined
	error?: boolean
	disabled?: boolean
	className?: string
	append?: JSX.Element | null
}> = (props) => {
	const [open, setOpen] = useState(false)
	const [value, setValue] = useState(props.value)
	const ref = useRef<HTMLDivElement>(null)
	const toggle = () => {
		setOpen((current) => {
			return current ? false : true
		})
	}

	const handleClickOutside = () => {
		setOpen(false)
	}

	useDetectClickOutside(ref, handleClickOutside)

	const handleSelections = (v: string) => {
		if (props.disabled) {
			return
		}

		setValue(v)
		props.onSelect(v)
		setOpen(false)
	}

	useEffect(() => {
		setValue(props.value)
	}, [props.value])

	return (
		<div className={`button-select ${open ? 'button-select--open' : ''} ${props.className}`} ref={ref}>
			<div className="button-select__inner" key={`bearbeitende-${value !== undefined ? value : ''}`}>
				{!(value === undefined && open) && (
					<Button onClick={toggle} className="button-select__current-value">
						<span>{props?.options.find((option) => option.value === value)?.label}</span>
						{
							<Icon
								color={open ? 'var(--color-black)' : undefined}
								rotate={open ? -90 : 90}
								type={IconType.arrow}
								className="button-select__arrow"
							></Icon>
						}
					</Button>
				)}

				{open && props.options && (
					<div className="button-select__options">
						{props.options.map((v: any, i: number) => {
							// hide current value from the SelectOptions
							if (value === v.value) {
								return undefined
							}

							if ((value !== undefined && v === v.key) || !v) {
								return <div key={`no-person-${i}`}></div>
							}
							return (
								<div
									key={'bearbeiten-person-' + i}
									onClick={() => handleSelections(v.value)}
									className={`button-select__button ${props.disabled ? 'button-select__button--disabled' : ''
										}`}
								>
									{v.label}
								</div>
							)
						})}
					</div>
				)}

				{open && props.append}
			</div>
		</div>
	)
}
